import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./formcard.css";
import { constant } from "../../constant/constant";
import Bandhan from "../../assets/bandhan.png";
import Maxlogo from "../../assets/maxlogo.svg";

import { Button } from "react-bootstrap";
import { Display } from "react-bootstrap-icons";


function FormCard(props) {
  const [showIframe, setShowIframe] = useState(false);

  return (
    <div>
      {!showIframe ?
        <>
          <div className="max-logo">
            <img alt="" id="maxlogo" src={Maxlogo} width={"100px"} height={"100px"}></img>
          </div>
          <div id="parentbandhan">
            <div id="col-1">
              <img alt="" src={Bandhan} id="imgStyle"></img>
            </div>
            <div id="col-2">
              <div className="heading-right">
                <h1>Welcome TO BANDHAN METAVERSE</h1>
                <p>New Employee onboarding Experience</p>
                <div>
                  <a onClick={() => setShowIframe(true)} className="btn button-color">Start Now</a>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <iframe
          width="100%" height="100%" id=""
          src="https://maxlife.gmetri.com/maxlifebandhan"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; microphone; camera; display-capture; xr-spatial-tracking; xr;"
          frameBorder="0" allowFullScreen
        />


      }
    </div>


  );
  // return (
  //   <>
  //     <iframe
  //       width="100%" height="100%" id=""
  //       src="https://gmetri.gmetri.com/sso_test_dnd"
  //       allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; microphone; camera; display-capture; xr-spatial-tracking; xr;"
  //       frameBorder="0" allowFullScreen
  //     ></iframe>

  //   </>
  // );
}

export default FormCard;

