import React, { useState } from "react";
import Landing from "../landing/Landing";
import Header from "../common/Header";
import FormCard from "../common/FormCard";
import MobileVerification from "../mobileverification/MobileVerification";
import AbhaAddress from "../abhaaddress/AbhaAddress";
import SearchAbha from "../searchAbha/SearchAbha";
import DownloadCard from "../downloadcard/downloadcard";
import AbhaCardContent from "../common/abhacardcontent";
import Register from "../register/Register";
import { constant } from "../../constant/constant";
import PremiumFvideo from "../PremiumFvideo";

function Home() {
  const [formId, setFormId] = useState(1);
  const [cardTitle, setCardTitle] = useState("");
  const [abhaCardDetails, setAbhaCardDetails] = useState(null);

  const showRegisterForm = (e) => {
    setCardTitle(constant.REACT_APP_AadharVerification_CardTitle);
    setFormId(2);
  };

  const showMobileVerification = (e) => {
    setCardTitle(constant.REACT_APP_Mobile_Number_Verification_CardTitle);
    setFormId(3);
  };

  const showAbhaAddressForm = (e) => {
    setCardTitle(constant.REACT_APP_AbhaAddress_CardTitle);
    setFormId(4);
  };

  const showSearchForm = (e) => {
    setCardTitle(constant.REACT_APP_SearchAbhaCard_CardTitle);
    setFormId(5);
  };

  const showDownloadAbhaForm = (abhaCardDetails) => {
    setCardTitle(constant.REACT_APP_DownLoadAbhaCard_CardTitle);
    setFormId(6);
    setAbhaCardDetails(abhaCardDetails);
  };

  const renderFormContent = (id) => {
    switch (id) {
      case 1:
        return (
          <PremiumFvideo/>
        );

      case 2:
        return <Register setMobileVerification={showMobileVerification} />;
      case 3:
        return <MobileVerification setAbhaAddressForm={showAbhaAddressForm} />;
      case 4:
        return <AbhaAddress setDownloadAbhaForm={showDownloadAbhaForm} />;
      case 5:
        return <SearchAbha setDownloadAbhaForm={showDownloadAbhaForm} />;
      case 6:
        return <DownloadCard abhaCardDetails={abhaCardDetails} />;
      default:
        return <PremiumFvideo />;
    }
  };

  return (
    <>
      {/* <Header /> */}
      <FormCard title={cardTitle} />
      {/* <AbhaCardContent /> */}
    </>
  );
}

export default Home;
