import React, { useState } from 'react';
import './Header.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { constant } from "../../constant/constant";
function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg bg-white shadow-sm">
          <div className="container">
            <a className="navbar-brand" href="index.html">

              <img src="https://www.maxlifeinsurance.com/static-page/assets/homepage/maxlogo.svg" alt="" width="80" height="auto" />
            </a>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;







